.theme_toggler {
  background: var(--primary-color);
  z-index: 99999;
  left: 10px;
  background: var(--primary-color);
  display: flex;
  height: 50px;
  align-items: center;
  padding-left: 10px;
}

.theme_toggler svg {
  width: 2em;
  height: 2em;
}
