.contact-me {
  margin: 20px;
  margin-right: 20px;
}
.contact-container {
  display: flex;
  flex-direction: column;
}

.centered-text {
  text-align: center;
}

.full-width-divider {
  width: 100%;
}

TextField {
  color: var(--primary-color);
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin-bottom: 15px; /* Adjust the margin-bottom value for more or less spacing */
}
p {
  margin-bottom: 20px;
}
