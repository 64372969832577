.site_header {
    display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-brand {
  text-decoration: none;
}

.home {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.menu_theme {
  display: flex;
}

.menu__button {
  color: var(--text-color);
}

.menu__button:focus,
.menu__button:hover {
  color: var(--text-color);
  box-shadow: unset;
}

.menu__button svg {
  width: 2em;
  height: 2em;
  fill: var(--text-color-2);
  color: var(--text-color-2);
}

.nav_ac {
  padding: 5px 15px;
  margin: 0;
  border: unset;
  background: var(--primary-color);
  font-size: 1.25rem;
  font-family: 'Libre Bodoni', serif;
  color: var(--text-color-2);
  line-height: 2;
  height: 50px;
  font-weight: bold;
  z-index: 3;
}

.nav_ac:hover {
  color: var(--text-color-2);
}

.site__navigation {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden;
}

.menu__opend {
  visibility: visible !important;
  z-index: 2;
}

.main__menu_ul,
.menu_right {
  opacity: 0;
  position: relative;
  transition: 0.5s;
  transition-delay: 0s;
  visibility: hidden;
}

.menu_right {
  text-align: center;
}

.bg__menu {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  will-change: transform;
  transform: translateY(-100%);
  transition: 0.5s ease all;
  }

.menu__opend .bg__menu {
  transform: translateY(0);
}

.the_menu {
  padding-top: 20vh;
  padding-bottom: 20vh;
  padding-left: 0;
}

@media (min-width: 992px) {
  .menu__container {
    margin-left: 33.3333%;
  }
  .the_menu {
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
}

.the_menu .menu_item > a {
  color: var(--text-color-2);
  line-height: 1;
  font-size: 2rem;
  display: inline-block;
  position: relative;
  transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
  padding: 4px 0px;
  text-decoration: none;
  font-family: 'Libre Bodoni', serif;
}

.the_menu .menu_item > a:hover {
  color: var(--text-color-3);
}

@media (min-width: 768px) {
  .the_menu .menu_item > a {
    font-size: 4.8vw;
  }
}

.menu_footer {
  bottom: 0;
  font-family: Marcellus;
  font-size: 14px;
  background: var(--primary-color);
}

.menu_footer a {
  color: var(--text-color-2);
  margin-right: 10px;
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .menu__container {
    margin-left: 23.3333%;
  }
}
