.contact-me {
  margin: 20px;
  margin-right: 20px;
}
.contact-container {
  display: flex;
  flex-direction: column;
}

.centered-text {
  text-align: center;
  font-family: 'Libre Bodoni, serif';
}

.full-width-divider {
  width: 100%;
}

TextField {
  color: var(--primary-color);
}
