.page-enter {
  transform: translateY(100%);
}

.page-enter-active {
  transform: translateY(0%);
  transition: all 400ms ease-out;
}

.page-exit {
  transform: translateY(0%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.header {
  position: fixed;
}

.page-exit-active {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(-130%);
  transition: all 400ms ease-out;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}
